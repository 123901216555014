import * as React from "react"
import styled from 'styled-components';
import Layout from "../components/Layout/Layout"
import Seo from "../components/seo"
import { media } from "utils/mediaQuery";
import { fontstack } from 'utils/fontstack';
import { type } from 'utils/type';

const Wrapper = styled.div`
  display: none;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 16px;
  padding: 16px 16px 0;
  position: relative;
  width: 100%;
  box-sizing: border-box;

  ${media.large`
    grid-template-columns: repeat(24, 1fr);
    display: grid;
  `}
`

const Column = styled.div`
  display: none;
  position: relative;
  grid-column: span 3;

  ${media.large`
    grid-column: 9 / span 8;
    display: block;
  `}

  ${media.xlarge`
    grid-column: 9 / span 6;
  `}
`

const Copy = styled.div` 
  ${fontstack.default}
  ${type('body')}
  color: rgb(var(--theme-primary));
`

const MobileCopy = styled.div` 
  margin: 138px 0 0;
  padding: 0 16px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  ${media.large`
    display: none;
  `}
  
`

const MobileCopyInner = styled.div`
  grid-column: 1 / span 6;
  ${media.small`
    grid-column: 1 / span 3;
  `}
`



const NotFoundPage = () => (
  <Layout navposition='fixed' omitCopy excludefooter>
    <Seo title="404" />
    <Wrapper>
      <Column>
        <Copy>
        404<br/>Page Not Found
        </Copy>
      </Column>
    </Wrapper>
    <MobileCopy>
        <MobileCopyInner>
          <Copy>
          404<br/>Page Not Found
          </Copy>
        </MobileCopyInner>
      </MobileCopy>
  </Layout>
)

export default NotFoundPage
